import React, { useEffect, useState } from "react";
import { useTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BookmarkButton } from "./BookmarkButton";
import { useAuth } from "../Contexts/AuthProvider";

export function ComplexCard(props) {
  const { DB } = useAuth();
  const [article, setArticle] = useState(null);
  const [visited, setVisited] = useState(false);

  const CardStyle = {
    display: "flex",
    maxWidth: "100%",
    minHeight: 135,
    textDecoration: "none",
  };

  const ImgStyle = {
    width: "37%",
    cursor: "pointer",
  };

  useEffect(async () => {
    if (!props.article.saved) {
      props.article.saved = false;
    }
    if (!props.article.visited) {
      props.article.visited = false;
    }
    setArticle(props.article);
    setVisited(props.article.visited);
  }, [props]);

  if (!article) {
    return null;
  }

  let desc = "";

  const LinkClick = async () => {
    setVisited(true);

    const dbUpdateResult = await DB.recs.update(props.article.order, {
      visited: true,
    });
  };

  const ConditionalCardMedia = () => {
    if (article.image) {
      return (
        <CardMedia
          component="img"
          sx={ImgStyle}
          image={article.image}
          alt={article.title}
          loading="lazy "
          onClick={() => {
            LinkClick();
            window.open(article.link);
          }}
        />
      );
    }
    return <Box sx={{ display: "block", height: "100%", width: "37%" }} />;
  };

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: article.title,
          text: `${article.title} | via rundown.day`,
          url: article.link,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const sourceName = (article) => {
    console.log(article);
    if (article.feed_source_name) {
      return article.feed_source_name;
    }
    if (article.feed_source_uri) {
      return article.feed_source_uri;
    }
    const uri = new URL(article.link);
    return uri.hostname;
  };

  const titleHtml = (string) => {
    return { __html: string };
  };

  const ShareButton = () => {
    if (navigator.share) {
      return (
        <IconButton onClick={share} size="small" color="primary">
          <SendIcon />
        </IconButton>
      );
    }
    return null;
  };
  if (article.description) {
    desc = article.description.substr(0, 140) + "...";
  }

  if (visited) {
    CardStyle.opacity = 0.5;
    ImgStyle.filter = "grayscale(1)";
  }
  return (
    <>
      <Card id={article.id} sx={CardStyle}>
        <ConditionalCardMedia />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "63%",
            position: "relative",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto", pb: "25px" }}>
            <Typography
              variant="h6"
              component="a"
              target="_blank"
              sx={{
                textDecoration: "none",
                color: "inherit",
                fontSize: "1rem",
                fontWeight: "700",
              }}
              href={article.link}
              onClick={LinkClick}
            >
              <span dangerouslySetInnerHTML={titleHtml(article.title)} />
            </Typography>
            <Typography
              sx={{
                fontSize: ".7rem",
              }}
              variant="subtitle1"
              color="text.secondary"
              component="p"
            >
              {sourceName(article)}
            </Typography>
          </CardContent>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 5,
            }}
          >
            <ShareButton />
          </Box>
        </Box>
      </Card>
    </>
  );
}
