import React, { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Chip } from "@mui/material";
import { useAuth } from "../Contexts/AuthProvider";
import { supabase } from "../Supabase";

export function FeedChip(props) {
  const { user } = useAuth();
  const [following, setFollowing] = useState(false);

  useEffect(() => {
    if (props.feed.CurrentlySubscribed) {
      setFollowing(true);
    }
  }, [props]);

  const toggleFeed = async () => {
    if (following) {
      const { data, error } = await supabase
        .from("users_to_content_sources")
        .delete()
        .match({
          user_id: user.id,
          content_source_id: props.feed.uuid,
        });
      if (data) {
        setFollowing(false);
        return;
      }
    }
    const { data, error } = await supabase
      .from("users_to_content_sources")
      .insert([
        {
          user_id: user.id,
          content_source_id: props.feed.uuid,
        },
      ]);
    if (data) {
      setFollowing(true);
    }
  };

  if (following) {
    return (
      <Chip
        id={props.feed.origin_domain}
        label="Unfollow"
        variant=""
        sx={{ m: 0.25 }}
        onClick={toggleFeed}
      />
    );
  }

  return (
    <Chip
      id={props.feed.origin_domain}
      label="Follow"
      variant="outlined"
      sx={{ m: 0.25 }}
      onClick={toggleFeed}
    />
  );
}

function Source(props) {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={props.feed.origin_domain} src={props.feed.image} />
        </ListItemAvatar>
        <ListItemText
          primary={props.feed.source_name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              ></Typography>
              {props.feed.origin_domain}
            </React.Fragment>
          }
        />
        <FeedChip feed={props.feed} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}

export function FeedItem(props) {
  const { user } = useAuth();

  if (!props.feed) {
    return null;
  }
  return <Source feed={props.feed} />;
}
