import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import fletching from "fletching";
import Dexie from "dexie";
import { supabase } from "../Supabase";
import { Schedule } from "@mui/icons-material";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Check active sessions and sets the user
    const session = supabase.auth.session();
    const goTo = (path) => navigate(path);
    setUser(session?.user ?? null);
    setLoading(false);

    // Listen for changes on auth state (logged in, signed out, etc.)
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setUser(session?.user ?? null);
        setLoading(false);
        goTo("/");
      }
    );

    return () => listener?.unsubscribe();
  }, [navigate]);
  // Will be passed down to Signup, Login and Dashboard components

  const getRundownAPI = () => {
    const session = supabase.auth.session();
    return fletching({
      root: "https://feeds.rundown.day/api/v1",
      // root: "http://localhost:8787/api/v1",
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    });
  };

  const getDb = () => {
    const db = new Dexie("RundownDB");
    db.version(1).stores({
      recs: "++order,&id,title,visited,saved",
    });

    db.version(2)
      .stores({
        recs: "++order,&id,title,visited,saved",
      })
      .upgrade((trans) => {
        var YEAR = 365 * 24 * 60 * 60 * 1000;
        return trans.recs.toCollection().modify((item) => {
          item.saved = false;
        });
      });
    return db;
  };

  const value = {
    signUp: (data) => supabase.auth.signUp(data),
    signIn: (data) =>
      supabase.auth.signIn(data, {
        redirectTo:
          process.env.REACT_APP_SITE_REDIRECT || "https://rundown.day",
      }),
    signOut: () => supabase.auth.signOut(),
    getSession: () => supabase.auth.session(),
    user,
  };

  if (value.user) {
    value.RundownAPI = getRundownAPI();
    value.DB = getDb();
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
