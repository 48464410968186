import React, { useEffect, useState } from "react";
import { useAuth } from "../Contexts/AuthProvider";
import { useNavigate } from "react-router";
import { supabase } from "../Supabase";
import { FeedChip, FeedItem } from "./FeedChip";
import { Grid, Container, Chip, Stack } from "@mui/material";
import List from "@mui/material/List";

export default function FeedList() {
  const { user } = useAuth();
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    (async () => {
      const { data: userFeedList, error } = await supabase
        .from("users_to_content_sources")
        .select("content_sources(source_generated_id)")
        .eq("user_id", user.id);

      const currentFeeds = userFeedList.map(({ content_sources }) => {
        return content_sources.source_generated_id;
      });

      const { data: allFeeds, error: feedError } = await supabase
        .from("content_sources")
        .select();

      const feedsMap = allFeeds.map((item) => {
        if (currentFeeds.includes(item.source_generated_id)) {
          item.CurrentlySubscribed = true;
          return item;
        }
        item.CurrentlySubscribed = false;
        return item;
      });

      if (feedsMap) {
        setFeeds(feedsMap);
      }
    })();
  }, []);

  const toggleFeedOn = async (userID, feedID) => {
    if (this.props.feed.CurrentlySubscribed) {
      console.log("NOPE!");
      return;
    }

    const { data, error } = await supabase
      .from("users_to_content_sources")
      .insert([{ user_id: user.id, content_sources_id: this.props.feed.uuid }])
      .select();

    if (data) {
      console.log(data);
      const feedsMap = feeds.map((item) => {
        if (feedID === item.uuid) {
          item.CurrentlySubscribed = true;
          return item;
        }
        item.CurrentlySubscribed = false;
        return item;
      });
      setFeeds(feedsMap);
    }
  };

  return (
    <List sx={{ maxWidth: "100%", bgcolor: "background.paper" }}>
      {feeds.map((feed) => (
        <FeedItem key={feed.uuid} feed={feed} />
      ))}
    </List>
  );
}
