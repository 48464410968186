import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useAuth } from "../Contexts/AuthProvider";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import FeedIcon from "@mui/icons-material/Feed";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { useNavigate } from "react-router-dom";

export default function MainMenu(props) {
  const { user } = useAuth();
  console.log(user);
  let navigate = useNavigate();
  let menuItems = [];
  if (user) {
    menuItems = [
      {
        icon: <NewspaperIcon />,
        key: "feed",
        name: "Home",
        onClick: () => {
          navigate("/");
          props.toggleDrawer();
        },
      },
      {
        icon: <FeedIcon />,
        key: "sources",
        name: "Sources",
        onClick: () => {
          navigate("/sources");
          props.toggleDrawer();
        },
      },
      {
        icon: <ViewComfyIcon />,
        key: "wordle",
        name: "Wordle",
        onClick: () => {
          props.toggleDrawer();
          window.open("https://www.nytimes.com/games/wordle/index.html");
        },
      },
    ];
  }

  return (
    <Box sx={{ pt: 6, minWidth: 250 }} role="presentation">
      <List>
        {menuItems.map((menuItem, index) => (
          <ListItem button key={menuItem.key} onClick={menuItem.onClick}>
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText primary={menuItem.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
}
