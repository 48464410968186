import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import List from "@mui/icons-material/List";
import GoogleIcon from "@mui/icons-material/Google";

import { supabase } from "../Supabase";

import { useAuth } from "../Contexts/AuthProvider";
import { Typography } from "@mui/material";

const signInWithGoogle = async () => {
  try {
    const { error } = await supabase.auth.signIn(
      {
        provider: "google",
      },
      {
        redirectTo:
          process.env.REACT_APP_SITE_REDIRECT ||
          "https://jdcauley-memabehq-rundown-app-ui-4w44q5xc7xwg-3000.githubpreview.dev",
      }
    );
    if (error) throw error;
  } catch (error) {
  } finally {
  }
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("success");
  // Get signIn function from the auth context
  const { signIn } = useAuth();

  async function handleSubmit(evt) {
    evt.preventDefault();

    try {
      setLoading(true);
      const { error } = await signIn({ email: email.trim() });
      if (error) throw error;
      setMessage("Check your email for the login link!");
      setStatus("success");
    } catch (error) {
      setMessage(error.error_description || error.message);
      setStatus("error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <h1 className="header">Sign In via Magic Link</h1>

      {message && <Alert severity={status}>{message}</Alert>}

      <form onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
        />

        <Button type="submit" fullWidth variant="contained" disabled={loading}>
          {loading ? <span>Loading</span> : <span>Send Magic Link</span>}
        </Button>
      </form>
    </>
  );
}
