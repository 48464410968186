import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import fletching from "fletching";
import { ComplexCard } from "./Components/Card";
import { useParams } from "react-router-dom";
import { useAuth } from "./Contexts/AuthProvider";
import SpeedDial from "@mui/material/SpeedDial";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { supabase } from "./Supabase";

export default function ContentFeed() {
  const { user, getSession, RundownAPI, DB } = useAuth();
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [recs, setRecs] = useState({
    feeds: [],
    searches: [],
  });

  const shouldRefresh = async (initialLoad) => {
    let shouldRefresh = true;
    const lastRefresh = localStorage.getItem("lastRefresh");
    if (initialLoad.length === 0) {
      return false;
    }

    if (!lastRefresh) {
      return false;
    }

    const lastRefreshTime = new Date(parseInt(lastRefresh, 10));
    var oneHour = 60 * 60 * 1000;
    if (new Date() - lastRefreshTime < oneHour) {
      return false;
    }

    return true;
  };

  const refresh = async () => {
    setLoading(true);
    try {
      const { data } = await RundownAPI.get(`/user/${user.id}/feed`);
      const feedsInfo = await DB.recs.bulkPut(data);
    } catch (err) {
      console.log(err);
    }
    const entries = await DB.recs.limit(80).reverse().toArray();
    setRecs({ feeds: entries, searches: [] });
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(false);
    const timeNow = Date.now();

    localStorage.setItem("lastRefresh", `${timeNow}`);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const initialLoad = await DB.recs.reverse().toArray();
      try {
        const { data } = await RundownAPI.get(`/user/${user.id}/feed`);

        const feedsInfo = await DB.recs.bulkPut(data);
      } catch (err) {
        // catch and ignore error as it is only an issue because it succeeded
        // console.log(err);
      }
      const entries = await DB.recs.limit(80).reverse().toArray();
      setRecs({ feeds: entries, searches: [] });
      setLoading(false);
    })();
  }, []);

  const CustomLoadingButton = () => {
    if (loading) {
      return (
        <SpeedDial
          ariaLabel="Refresh Content"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
          icon={<CircularProgress color="inherit" />}
          onClick={refresh}
        ></SpeedDial>
      );
    }
    return (
      <SpeedDial
        ariaLabel="Refresh Content"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<RefreshIcon />}
        onClick={refresh}
      ></SpeedDial>
    );
  };

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 1 }}>
        <Grid container sx={{ mb: 10 }}>
          {recs.feeds.map((item) => {
            if (item.feed_source_uri && item.feed_source_uri.includes("http")) {
              const destruct = new URL(item.feed_source_uri);
              item.feed_source_uri = destruct.hostname;
            }
            return (
              <Grid key={item.id} item sx={{ mb: 3, maxWith: "100%" }}>
                <ComplexCard
                  key={item.id}
                  article={item}
                  user_id={user.id}
                ></ComplexCard>
              </Grid>
            );
          })}

          {recs.searches.map((feed) => {
            return (
              <div key={feed.keyword}>
                <Typography variant="p" component="p" gutterBottom>
                  {feed.feed}
                </Typography>
                {feed.results.map((item) => {
                  return (
                    <Grid key={item.id} item sx={{ mb: 6 }}>
                      <ComplexCard key={item.id} article={item}></ComplexCard>
                    </Grid>
                  );
                })}
              </div>
            );
          })}
        </Grid>
      </Container>
      <CustomLoadingButton />
    </>
  );
}
