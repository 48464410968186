import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import FeedList from "./Components/FeedList";
import SpeedDialTooltipOpen from "./Components/SpeedDial";
import ContentFeed from "./ContentFeed";
import { useAuth } from "./Contexts/AuthProvider";
import { Login } from "./Components/Login";

export function Home(props) {
  const { user } = useAuth();
  const { posts, title } = props;

  if (!user) {
    return (
      <Container maxWidth="md">
        <Login />
      </Container>
    );
  }

  return <ContentFeed />;
}
