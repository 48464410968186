import * as React from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import List from "@mui/icons-material/List";
import FeedIcon from "@mui/icons-material/Feed";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { useNavigate } from "react-router-dom";

const actions = [
  {
    icon: <FeedIcon />,
    name: "Sources",
    onClick: () => {
      window.location.href = "/sources";
    },
  },
  {
    icon: <List />,
    name: "Feed",
    onClick: () => {
      window.location.href = "/";
    },
  },
];

export default function SpeedDialTooltipOpen() {
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 15,
        right: 15,
        transform: "translateZ(0px)",
        flexGrow: 1,
      }}
    >
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <SpeedDialAction
          key="wordle"
          icon={<ViewComfyIcon />}
          tooltipTitle="Wordle"
          tooltipOpen
          component="a"
          onClick={() => {
            window.open("https://www.nytimes.com/games/wordle/index.html");
          }}
        />
        <SpeedDialAction
          key="sources"
          icon={<FeedIcon />}
          tooltipTitle="Sources"
          tooltipOpen
          component="a"
          onClick={() => {
            navigate("/sources");
          }}
        />
        <SpeedDialAction
          key="home"
          icon={<NewspaperIcon />}
          tooltipTitle="Home"
          tooltipOpen
          component="a"
          onClick={() => {
            navigate("/");
          }}
        />
      </SpeedDial>
    </Box>
  );
}
