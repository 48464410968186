import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FeedList from "./Components/FeedList";
import { useAuth } from "./Contexts/AuthProvider";

export default function SourceManager(props) {
  const { user } = useAuth();
  const { posts, title } = props;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar>
          <Typography component="h1" sx={{ flexGrow: 1, fontSize: "1.4rem" }}>
            Primary Sources
          </Typography>
        </Toolbar>
      </Box>
      <Grid sx={{ mb: 10 }}>
        <Divider />
        <FeedList />
      </Grid>
    </>
  );
}
