import React from "react";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";

import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ContentFeed from "./ContentFeed";
import { AuthProvider } from "./Contexts/AuthProvider";
import { PrivateRoute } from "./Components/PrivateRoute";
import { Home } from "./Home";
import MenuDrawer from "./Components/MenuDrawer";
import MainMenu from "./Components/MainMenu";
import SourceManager from "./SourceManager";
import SpeedDialTooltipOpen from "./Components/SpeedDial";

import { Login } from "./Components/Login";

export const AppRoutes = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDrawer = () => {
    if (menuOpen) {
      return setMenuOpen(false);
    }
    return setMenuOpen(true);
  };

  const menu = () => <MainMenu toggleDrawer={toggleDrawer} />;

  return (
    <AuthProvider>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="relative" sx={{ zIndex: 1400 }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              The Rundown
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <Drawer anchor="right" open={menuOpen} onClose={toggleDrawer}>
            <MainMenu toggleDrawer={toggleDrawer} />
          </Drawer>
        </AppBar>
      </Box>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/feed"
          element={
            <PrivateRoute>
              <ContentFeed />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/sources"
          element={
            <PrivateRoute>
              <SourceManager />
            </PrivateRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};
